'use client';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { AccountUpdateableState } from '~/generated/graphql';
export function OnboardingStageField(props: {
  stage: AccountUpdateableState;
}) {
  const profileForm = useFormContext();
  const field = 'state';
  useEffect(() => {
    // for some reason form state persists across navigation
    profileForm.setValue(field, props.stage);
  }, []);
  return <input type="hidden" readOnly={true} {...profileForm.register(field, {
    value: props.stage
  })} data-sentry-component="OnboardingStageField" data-sentry-source-file="OnboardingStageField.tsx" />;
}