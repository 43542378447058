import { produce } from 'immer'
import { type InterpreterFrom, type SnapshotFrom, assign, createMachine, fromPromise, raise } from 'xstate'
import * as Sentry from '@sentry/browser'
import { AccountState, TrimmedCurrentAccountFragment, TrimmedCurrentUserFragment } from '~/generated/graphql'
import { appTrace } from '~/utils'
import { localStorageGetter, localStorageSetter } from '~/utils/local-storage'

import { MachineDebugger } from '../common/debugger'
import { restoreApiToken } from './actors/restoreApiToken'
//
import { type AuthContext, initContext } from './context'
import type { AuthEvents } from './events'
import type { AuthOptions } from './options'

export const ACCESS_TOKEN_KEY = 'api-access-token'
export type AuthMachineCtx = ReturnType<typeof createAuthMachine>
export type AuthActor = InterpreterFrom<AuthMachineCtx['machine']>
export type AuthSnapshot = SnapshotFrom<AuthActor>

export type AuthActors = {
  // src: string
  // logic: any
  authenticate: {
    input: {
      context: AuthContext
    }
    output: Promise<{
      user: TrimmedCurrentUserFragment
      account: TrimmedCurrentAccountFragment
      token: string
    }>
  }
  restoreApiToken: { output: Promise<{ token?: string }> }
}
//
export function createAuthMachine({ clientStorageType = 'web', clientStorage, autoSignIn = true }: AuthOptions) {
  const storageGetter = localStorageGetter(clientStorageType, clientStorage)
  const storageSetter = localStorageSetter(clientStorageType, clientStorage)
  const id = 'auth'
  const md = new MachineDebugger({ id })

  const machine = createMachine(
    {
      types: {
        //typegen: {}as TypegenEnabled,
        context: {} as AuthContext,
        events: {} as AuthEvents,
        //actors: {} as AuthActors,
        //actors: {},
      },
      context: initContext(),
      id,
      type: 'parallel',

      on: {
        CURRENT_ACCOUNT: {
          actions: [
            assign(({ context, event }) =>
              produce(context, (ctx) => {
                ctx.account = event.data.account
              })
            ),
            'setSentryUser',
          ],
        },
        CURRENT_USER: {
          actions: [
            assign(({ context, event }) =>
              produce(context, (ctx) => {
                ctx.user = event.data.user
              })
            ),
            'setSentryUser',
          ],
        },
        CURRENT_AUTH_CTX: {
          reenter: true,
          actions: [
            md.logAction('new auth ctx'),
            assign(({ context, event }) =>
              produce(context, (ctx) => {
                ctx.account = event.data.account
                ctx.user = event.data.user
              })
            ),
            'reportAuthCtxLoaded',
            'setSentryUser',
          ],
        },
        API_SIGNED_OUT: {
          actions: [
            md.logAction('api signed out'),
            assign(({ context }) =>
              produce(context, (ctx) => {
                ctx.account = null
                ctx.user = null
              })
            ),
            'setSentryUser',
          ],
        },
      },

      states: {
        provider: {
          initial: 'unknown',
          states: {
            unknown: {
              entry: [md.logAction('prov.unknown entry')],
              exit: [md.logAction('prov.unknown exit')],
            },
            signedIn: {
              entry: [md.logAction('prov.sigendIn entry'), 'reportProviderSignedIn'],
              exit: [md.logAction('prov.signedIn exit')],
            },
            signedOut: {
              entry: [md.logAction('prov.signedOut entry')],
              exit: [md.logAction('prov.signedOut exit')],
            },
          },
          on: {
            AUTH0_TOKEN_UPDATE: {
              description: 'Triggered by Auth0 provider',
              actions: [
                md.logAction('auth0: token received'),
                assign(({ context, event }) =>
                  produce(context, (ctxDraft) => {
                    ctxDraft.auth0token = event.data.token
                    md.consoleLog('saved auth0 token')
                  })
                ),
              ],

              target: '.signedIn',
            },
            AUTH0_TOKEN_UNAVAILABLE: {
              actions: [
                assign(({ context, event }) =>
                  produce(context, (ctxDraft) => {
                    // WARNING: technically this should not be necessary
                    if (ctxDraft.auth0token) ctxDraft.auth0token = null
                  })
                ),
                md.logAction('auth0: token unavailable'),
              ],
              target: '.signedOut',
            },
          },
        },
        api: {
          initial: 'unknown',
          on: {
            SIGNOUT: {
              actions: ['resetContext'],
              target: '.signedOut',
            },
            API_TOKEN_SET: {
              actions: [
                md.logAction('api: token set'),
                assign(({ context, event }) =>
                  produce(context, (ctxDraft) => {
                    ctxDraft.accessToken.value = event.data.token
                  })
                ),
                'storeApiToken',
              ],
              target: '.signedIn',
            },
            CURRENT_AUTH_CTX_LOADED: {
              target: '.signedIn',
            },
          },
          states: {
            unknown: {
              entry: md.logAction('api.unknown entry'),
              exit: md.logAction('api.unknown exit'),
              invoke: {
                id: 'restoreApiToken',
                src: fromPromise(({ input }) => restoreApiToken(input)),
                input: ({ context }) => ({
                  context,
                  storageGetter,
                }),
                onDone: {
                  description: 'Might be invalid / expired',
                  actions: [
                    raise(({ event }) => ({ type: 'API_TOKEN_SET' as const, data: { token: event.output.token } })),
                    md.logAction('api: restored'),
                  ],
                  target: 'signedIn',
                },
                onError: [
                  {
                    description: 'triggers even after successful api signin',
                    guard: 'isApiTokenUnavailable',
                    actions: md.logAction('api-token-not-restored'),
                    target: 'signedOut',
                  },
                  {
                    actions: md.logAction('api-token-not-restored-catch-all'),
                  },
                ],
              },
            },
            signedIn: {
              description: 'Enters when we got a possibly invalid JWT token',
              entry: [md.logAction('api: sign-in done'), 'reportApiSignedIn', 'broadcastToken'],
            },
            signedOut: {
              entry: [
                md.logAction('api.signedOut entry'),
                'resetStorage',
                // WARNING:
                // Don't clear context because we want to keep auth0token
                // - the provider could be signed in while the api is not
                // 'clearContext',
                'reportApiSignedOut',
              ],

              exit: md.logAction('api.signedOut exit'),
            },
          },
        },
        registration: {
          initial: 'unknown',
          on: {
            SIGNOUT: '.unknown',
            CURRENT_AUTH_CTX_LOADED: '.onboarding',
          },
          states: {
            unknown: {
              entry: [md.logAction('reg.unknown entry')],
              exit: [md.logAction('reg.unknown exit')],
              on: {
                API_SIGNED_IN: 'loading',
                PROVIDER_SIGNED_IN: {
                  target: 'loading',
                  actions: md.logAction('reg: unknown->loading bc of prov sign in'),
                },
              },
            },
            loading: {
              entry: [md.logAction('reg.loading entry')],
              exit: [md.logAction('reg.loading exit')],
              description: 'Can be done with either API or Provider tokens',
              initial: 'executing',
              states: {
                executing: {
                  invoke: {
                    id: 'authenticate',
                    src: 'authenticate',
                    input: ({ context }) => ({ context }),
                    onDone: [
                      {
                        actions: [
                          md.logAction('registration.loading: done - start'),
                          raise(({ event }) => ({
                            type: 'API_TOKEN_SET' as const,
                            data: { token: event.output.token },
                          })),
                          raise(({ event }) => ({
                            type: 'CURRENT_AUTH_CTX' as const,
                            data: { user: event.output.user, account: event.output.account },
                          })),
                          'reportApiSignedIn',
                          'reportAuthCtxLoaded',
                          md.logAction('registration.loading: done - end'),
                        ],
                      },
                    ],
                    onError: {
                      target: 'error',
                      actions: md.logAction('loading error'),
                    },
                    onSnapshot: [
                      {
                        actions: md.logAction('registration.loading: authenticate snapshot'),
                      },
                    ],
                  },
                },
                // TODO this one might be buggy
                // because non-spicified targets are implicitly set to the
                // initial states
                //
                // none of this is tested
                error: {
                  entry: [md.logAction('reg.loading.error entry')],
                  exit: [md.logAction('reg.loading.error exit')],

                  always: [
                    {
                      guard: 'isApiTokenAvailable',
                      target: ['#auth.api.signedOut', '#auth.registration.unknown'],
                      actions: md.logAction('registration.loading: api sign out'),
                      description: 'invalid token, network error, blocked user?',
                    },
                    {
                      guard: 'isProviderSignedIn',
                      actions: md.logAction('registration.loading: error + provider'),
                      target: '#auth.registration.onboarding',
                    },
                    {
                      actions: md.logAction('registration.loading: unhandled error'),
                      target: '#auth.registration.unknown',
                    },
                  ],
                },
              },
            },
            onboarding: {
              initial: 'noUser',
              always: [
                {
                  guard: 'isUserActive',
                  target: 'complete',
                  actions: md.logAction('onboarding (active): moving to complete'),
                },
                {
                  guard: 'isUserSuspended',
                  target: 'suspended',
                  actions: md.logAction('onboarding (suspended): moving to suspended'),
                },
              ],

              states: {
                noUser: {
                  always: [
                    {
                      guard: 'isUserOnboarding',
                      target: 'userCreated',
                      actions: md.logAction('onboarding(no user): created!'),
                    },
                  ],
                },
                userCreated: {},
              },
            },
            complete: {
              entry: md.logAction('registration.complete entry'),
            },
            suspended: {
              entry: [md.logAction('reg.suspended entry')],
              exit: [md.logAction('reg.loading exit')],
            },
          },
        },
      },
    },
    {
      actions: {
        setSentryUser: ({ context }) => {
          if (!context.user) {
            Sentry.setUser(null)
          } else {
            Sentry.setUser({ id: context.user?.id, username: context.account?.profile?.name ?? undefined })
          }
        },
        reportProviderSignedIn: raise({ type: 'PROVIDER_SIGNED_IN' }),
        reportApiSignedOut: raise({ type: 'API_SIGNED_OUT' }),
        reportApiSignedIn: raise({ type: 'API_SIGNED_IN' }),
        reportAuthCtxLoaded: raise({ type: 'CURRENT_AUTH_CTX_LOADED' }),

        storeApiToken: ({ context }) => {
          if (!context.accessToken.value) {
            md.consoleError('attempt to store empty api token')
            return
          }
          storageSetter(ACCESS_TOKEN_KEY, context.accessToken.value)

          // if (typeof window !== 'undefined') {
          //   const now = new Date()
          //   now.setFullYear(now.getFullYear() + 10) // Set the expiration date to 10 years from now
          //   document.cookie = `${encodeURIComponent(
          //     ACCESS_TOKEN_KEY
          //   )}=${encodeURIComponent(
          //     ctx.accessToken.value
          //   )};expires=${now.toUTCString()};path=/`
          // }
        },
        resetStorage: () => storageSetter(ACCESS_TOKEN_KEY, null),
        clearContext: assign(() => initContext()),
        // * Broadcast the token to other tabs when `autoSignIn` is activated
        broadcastToken: ({ context }) => {
          if (autoSignIn) {
            try {
              const channel = new BroadcastChannel('colab')
              channel.postMessage(context.refreshToken.value)
            } catch (_error) {
              // * BroadcastChannel is not available e.g. react-native
            }
          }
        },
      },

      guards: {
        isApiTokenAvailable: ({ context }) => !!context.accessToken?.value,
        isApiTokenUnavailable: ({ context }) => !context.accessToken?.value,
        isProviderSignedIn: ({ context }) => !!context.auth0token,
        isUserOnboarding: ({ context }) =>
          //context.user?.state == UserState.Onboarding,
          //not depending on enums to account for multiple onboarding steps
          context.account?.state?.toString().startsWith('Onboarding') ?? false,
        //isUserActive: ({ context }) => context.user?.state == UserState.Active,
        isUserActive: ({ context }) => {
          md.consoleLog('isUserActive?', context.account?.state === AccountState.Active, context.account?.id)

          return context.account?.state === AccountState.Active
        },
        isUserSuspended: ({ context }) => {
          md.consoleLog('isUserSuspended?', context.account?.state === AccountState.Suspended, context.account?.id)

          return context.account?.state === AccountState.Suspended
        },
      },
    }
  )
  return { machine, debugger: md }
}
