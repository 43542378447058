'use client';

import dynamic from 'next/dynamic';
import type React from 'react';
const NoSsrFragment = ({
  children
}: {
  children: React.ReactNode;
}) => children;
export const NoSsr = dynamic(() => Promise.resolve(NoSsrFragment), {
  ssr: false
});