import React, { useContext } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import { Zoom } from 'yet-another-react-lightbox/plugins';
import { SortedFolioImage } from './SortedFolioImage';
import { PeerAccountContext } from '~/app/_components';
import { sortedFolioItems } from '~/app/(accounts)/_lib';
export function ProfilePhotoAlbum() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const {
    account
  } = useContext(PeerAccountContext);
  const lightboxImages = account.folioItems?.map(photo => ({
    src: photo.file.url,
    index: index
  }));
  function updateIndex({
    index
  }: {
    index: number;
  }) {
    setIndex(index);
  }
  const images = sortedFolioItems(account).map((image, i) => <SortedFolioImage key={image.id} fi={image} setIndex={setIndex} index={i} setOpen={setOpen} />);
  return <>
      {/* {account.videoItems?.map((vi) => (
        <VideoFolioItem key={vi.id} vi={vi} />
       ))} */}
      {/* {account.folioItems?.map((fi, i) => (
        <SortedFolioImage key={fi.id} fi={fi} setIndex={setIndex} index={i} setOpen={setOpen} />
       ))} */}
      {images}

      <Lightbox open={open} plugins={[Zoom]} captions={{
      descriptionTextAlign: 'start',
      descriptionMaxLines: 3,
      showToggle: true
    }}
    // zoom={{ ref: zoomRef }}
    close={() => setOpen(false)} index={index} slides={lightboxImages} on={{
      view: updateIndex
    }} animation={{
      fade: 0
    }} controller={{
      closeOnPullDown: true,
      closeOnBackdropClick: true
    }} data-sentry-element="Lightbox" data-sentry-source-file="ProfilePhotoAlbum.tsx" />
    </>;
}