import type { SelectProps } from '@mantine/core';
import { CommonSelectField } from '~/app/(me)/_components';
import { AccountType } from '~/generated/graphql';
export function AccountTypeField(props: SelectProps) {
  const {
    label,
    name,
    ...restProps
  } = props;
  return <CommonSelectField name="type" label="BUSINESS TYPE" size="md" placeholder="Select" data={[{
    value: AccountType.Brand,
    label: 'Brand'
  }, {
    value: AccountType.TalentAgency,
    label: 'Talent Agency'
  }]} clearable {...restProps} data-sentry-element="CommonSelectField" data-sentry-component="AccountTypeField" data-sentry-source-file="AccountTypeField.tsx" />;
}