'use client';

import { Input, Text, TextInput, type TextInputProps } from '@mantine/core';
import { RefObject, useEffect, useRef } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';
import { CommonFieldLabel } from '~/app/(me)/_components';
import { appTrace } from '~/utils';
import { formErrorText, textInputRoot } from '../styles.css';

// WARNING careful - minor changes to this widget can trigger browser crashes
export function CommonLocationField(props: TextInputProps & {
  name: string;
}) {
  const fieldName = props.name;
  return <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonLocationField" data-sentry-source-file="CommonLocationField.tsx">
      <CommonFieldLabel name={fieldName} label="LOCATION" data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonLocationField.tsx" />
      <CommonLocationFieldAutocomplete {...props} data-sentry-element="CommonLocationFieldAutocomplete" data-sentry-source-file="CommonLocationField.tsx" />
    </Input.Label>;
}
export function useLocationAutocomplete(inputRef: RefObject<HTMLInputElement | null>, onChange: (value: {
  formatted_address: string;
}) => void) {
  if (process.env.NEXT_PUBLIC_CI) return;
  const libraries: Libraries = ['places'];

  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  const {
    isLoaded,
    loadError
  } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBkXCw8fd_BRfOViPsgVTsOa9QIpDOCvHo',
    libraries
  });

  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  useEffect(() => {
    if (isLoaded && window.google) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const autocomplete = new (window as any).google.maps.places.Autocomplete(inputRef.current, {
        types: ['(cities)']
      });
      autocomplete.addListener('place_changed', () => {
        onChange(autocomplete.getPlace());
      });
    }
  }, [isLoaded]);

  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  useEffect(() => {
    if (loadError) throw new Error('Failed to load Google Maps API');
  }, [loadError]);
}
function CommonLocationFieldAutocomplete(props: TextInputProps & {
  name: string;
}) {
  const fieldName = props.name;
  const profileForm = useFormContext();
  // Subscribe to the field's value changes
  const value = useWatch({
    control: profileForm.control,
    name: fieldName
  });
  const {
    field,
    fieldState
  } = useController({
    name: fieldName
  });
  return <>
      <CommonAutocompleteInput w="100%" size="md" value={field.value} onChange={field.onChange} onPlaceSelected={place => {
      if (field.value === place.formatted_address) return;
      field.onChange(place.formatted_address);
    }} classNames={{
      input: textInputRoot
    }} {...props} data-sentry-element="CommonAutocompleteInput" data-sentry-source-file="CommonLocationField.tsx" />
      <input type="hidden" data-testid="location" onChange={event => {
      // needed for e2e tests
      if (value === event.target.value) return;
      appTrace('changing location', event.target.value);
      field.onChange(event.target.value);
    }} />
      {fieldState.error ? <Text className={formErrorText}>{fieldState.error.message}</Text> : undefined}
    </>;
}
export function CommonAutocompleteInput(props: TextInputProps & {
  autoclear?: boolean;
  onPlaceSelected: (value: {
    formatted_address: string;
  }) => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    onPlaceSelected,
    autoclear,
    ...restProps
  } = props;
  useLocationAutocomplete(inputRef, place => {
    onPlaceSelected(place);

    // Clear the input field after selecting a place
    if (autoclear) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }, 100);
    }
  });
  return <TextInput w="100%" type="text" autoCapitalize="true" ref={inputRef} {...restProps} data-sentry-element="TextInput" data-sentry-component="CommonAutocompleteInput" data-sentry-source-file="CommonLocationField.tsx" />;
}