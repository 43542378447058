'use client';

import { useContext, useEffect } from 'react';
import { type FieldPath, get, useFormContext } from 'react-hook-form';
import type { ProfileFormValues } from '~/app/(me)/_components/common/form/CurrentAccountFormProvider';
import { IdentityContext } from '~/app/_components';
import { appTrace } from '~/utils';
export function CurrentAccountValidationField(props: {
  name: FieldPath<ProfileFormValues>;
}) {
  const profileForm = useFormContext();
  const field = props.name;
  const setValue = profileForm.setValue;
  const {
    currentAccount
  } = useContext(IdentityContext);
  const prefix = 'currentAccount.';
  const cleanField = field.substring(prefix.length);
  useEffect(() => {
    const fieldValue = get(currentAccount, cleanField);
    appTrace('CurrentAccountValidationField mount', {
      field,
      fieldValue
    });
    setValue(field, fieldValue, {
      shouldValidate: true
    });
  }, []);
  useEffect(() => {
    const fieldValue = get(currentAccount, cleanField);
    appTrace('CurrentAccountValidationField changed', {
      field,
      fieldValue
    });
    setValue(field, fieldValue, {
      shouldValidate: true
    });
  }, [field, setValue, currentAccount]);
  return <input type="hidden" readOnly={true} {...profileForm.register(field)} data-sentry-component="CurrentAccountValidationField" data-sentry-source-file="CurrentAccountValidationField.tsx" />;
}