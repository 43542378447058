import type { SelectProps } from '@mantine/core';
import { CommonSelectField } from '~/app/(me)/_components';
import { UserGender } from '~/generated/graphql';
export function ProfileGenderField(props: SelectProps) {
  const {
    label,
    name,
    ...restProps
  } = props;
  return <CommonSelectField label="GENDER" name="profile.gender" placeholder="Select your identified gender" size="md" data={[{
    value: UserGender.Female,
    label: 'Female'
  }, {
    value: UserGender.Male,
    label: 'Male'
  }, {
    value: UserGender.Nonbinary,
    label: 'Non-Binary/Gender Nonconforming'
  }, {
    value: UserGender.Other,
    label: 'Other'
  }]} {...restProps} data-sentry-element="CommonSelectField" data-sentry-component="ProfileGenderField" data-sentry-source-file="GenderField.tsx" />;
}