import { AtSymbolIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Flex, Input } from '@mantine/core';
import { useContext } from 'react';
import { CommonTextField } from '~/app/(me)/_components/common/form';
import { AppLayoutContext, SafeImage } from '~/app/_components';
export function ContactInfoFields({
  isOnboarding
}: {
  isOnboarding?: boolean;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <Flex direction="column" data-sentry-element="Flex" data-sentry-component="ContactInfoFields" data-sentry-source-file="ContactInfoFields.tsx">
      <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
        CONTACT INFO
      </Input.Label>

      <Flex gap="sm" align="flex-end" justify="space-between" mb="sm" direction="column" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
        <CommonTextField required size={isOnboarding ? mobile ? 'md' : 'md' : 'md'} description="Your email and phone number will be shared with users you connect with." leftSection={<EnvelopeIcon height="20px" width="20px" color="black" />} name="contactInfo.email" placeholder="Email" type="email" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
        <CommonTextField size={isOnboarding ? mobile ? 'md' : 'md' : 'md'} leftSection={<PhoneIcon height="20px" width="20px" color="black" />} name="contactInfo.phone" placeholder="Phone number (optional)" type="tel" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
      </Flex>
      <Flex direction="column" gap="sm" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
        <CommonTextField name="socialInfo.website" description="The following information will be shared with all users." size={isOnboarding ? mobile ? 'md' : 'md' : 'md'}
      // leftSection={<GlobeAltIcon height="20px" width="20px" color='black'/>}
      leftSection={<SafeImage alt="avatar" height={20} width={20}
      // sizes={`${size}px`}
      style={{
        objectFit: 'cover'
      }} src={'/icons/WebIcon.webp'}
      // sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, (min-width: 1407px) 33vw"
      />} placeholder="Website (optional)" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
        <CommonTextField name="socialInfo.igHandle" size={isOnboarding ? mobile ? 'md' : 'md' : 'md'} leftSection={<AtSymbolIcon height="20px" width="20px" color="black" />}
      // leftSection={
      //   <SafeImage
      //     alt="avatar"
      //     height={15}
      //     width={15}
      //     // sizes={`${size}px`}
      //     style={{
      //       objectFit: 'cover',
      //     }}
      //     src={'/icons/AtIcon.webp'}
      //     // sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, (min-width: 1407px) 33vw"
      //   />
      // }
      placeholder="Instagram username (optional)" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
      </Flex>
    </Flex>;
}