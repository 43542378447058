// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { InviteModal } from '~/app/(accounts)/_components';
import { globalTheme } from '~/styles/mantineThemeOverride';
import { PropsWithChildren } from 'react';
import { fontPoppins } from '~/styles/font';

// this is needed because importint fontPoppins inside of globalTheme triggers
// "No template for dependency: CssDependency" error in webpack
const mergedTheme: MantineThemeOverride = {
  ...globalTheme,
  fontFamily: fontPoppins.style.fontFamily
};
export function ThemeLayout({
  children
}: PropsWithChildren) {
  return <MantineProvider withCssVariables theme={mergedTheme} data-sentry-element="MantineProvider" data-sentry-component="ThemeLayout" data-sentry-source-file="ThemeLayout.tsx">
      <ModalsProvider modals={{
      connect: InviteModal
    }} data-sentry-element="ModalsProvider" data-sentry-source-file="ThemeLayout.tsx">{children}</ModalsProvider>
      <Notifications data-sentry-element="Notifications" data-sentry-source-file="ThemeLayout.tsx" />
    </MantineProvider>;
}