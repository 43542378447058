'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues } from 'react-hook-form';
import * as z from 'zod';
import { ZodObject } from 'zod';
import { appTrace } from '~/utils/tracing';
export function extractProfessions(type: 'connect' | 'set', ids?: string[]) {
  if (!ids) return {};
  return type === 'connect' && ids ? {
    connect: ids.map(id => ({
      id
    }))
  } : {
    set: ids.map(id => ({
      id
    }))
  };
}
export const formErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type && ['null', 'nan', 'undefined'].includes(issue.received)) {
    return {
      message: 'Required.'
    };
  }
  if (issue.code === z.ZodIssueCode.too_small && issue.type === 'string' && issue.minimum === 1) {
    return {
      message: 'Required.'
    };
  }
  return {
    message: ctx.defaultError
  };
};

// Utility function to create a dynamic schema based on the values passed
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function createDynamicSchema(schemaShape: any, values: any): any {
  return z.object(Object.fromEntries(Object.entries(values).map(([key, value]) => {
    const fieldSchema = schemaShape[key];
    if (!fieldSchema) return [key, z.any()]; // Allow any type if not in the schema

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      // Handle nested objects recursively
      return [key, createDynamicSchema(fieldSchema.shape || {}, value)];
    }
    return [key, fieldSchema]; // Use the schema type if available
  })));
}
export function createValidationResolver<TFieldValues extends FieldValues>(schema: ZodObject<any>) {
  return async (values: TFieldValues, context: any, options: any) => {
    const dynamicSchema = createDynamicSchema(schema.shape, values);
    const result = await zodResolver(dynamicSchema, {
      errorMap: formErrorMap
    })(values, context, options);
    // console.log('validated result1', JSON.stringify({ values }))
    // console.log('validated result2', JSON.stringify({ dynamicSchema }))
    // appTrace('validated result3', JSON.stringify(result)) //- GIVES RUNTIME ERRO

    const schemaKeys = new Set(Object.keys(schema.shape));
    const extraValues = Object.fromEntries(Object.entries(values).filter(([key]) => !schemaKeys.has(key)));
    // Merge the extra values back into the validated result
    const finalResult = {
      ...result,
      values: {
        ...result.values,
        ...extraValues
      }
    };
    return finalResult;
  };
}