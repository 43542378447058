import { CloseButton, Grid } from '@mantine/core';
import { useMutation } from '@urql/next';
import { useContext } from 'react';
import { updateCurrentAccount } from '~/app/(me)/_lib/updateCurrentAccount';
import { AppLayoutContext, IdentityContext } from '~/app/_components';
import { folioGridColumn, folioGridEditDeleteIcon } from './styles.css';
export function VideoGallery() {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [, executeUpdateAccount] = useMutation(updateCurrentAccount);
  function handleDeletePhoto(id: string) {
    executeUpdateAccount({
      account: {
        videoItems: {
          delete: [{
            id: id
          }]
        }
      }
    });
  }
  function getPlatform(platform: string, urlId: string) {
    return platform === 'youtube' ? `https://www.youtube.com/embed/${urlId}` : `https://player.vimeo.com/video/${urlId}`;
  }
  return <Grid gutter="5px" m={0} data-sentry-element="Grid" data-sentry-component="VideoGallery" data-sentry-source-file="VideoGallery.tsx">
      {currentAccount?.videoItems?.map(vi => <Grid.Col span="content" key={vi.id} className={folioGridColumn}>
          <>
            {/* <Image
             src={videoUrl}
             height="100"
             w="auto"
             fit="contain"
             data-testid="folio-video"
             /> */}
            <iframe title="Video player" src={getPlatform(vi.kind, vi.url)} width="150" height="100" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
          margin: '0 auto'
        }} />
            <CloseButton size={mobile ? 'sm' : 'xs'} aria-label="Remove" onClick={() => handleDeletePhoto(vi.id)} className={folioGridEditDeleteIcon} data-testid="delete-foliovideo-btn" />
          </>
        </Grid.Col>)}
    </Grid>;
}