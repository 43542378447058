'use client';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { Badge, Button, Flex, Modal, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { AppLayoutContext, WaitForTotalCount, useAppAuth } from '~/app/_components';
import { dekstopModalTitle } from '~/app/projects/styles.css';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { ProfessionsFilterContext } from '../ProfessionsFilterContext';
import { mobileSortControl } from '../mobile';
import { SearchFilterDesktop } from './SearchFilterDesktop';
import { filterButtonGroupLeft } from './styles.css';
function ResultsCount() {
  return <WaitForTotalCount data-sentry-element="WaitForTotalCount" data-sentry-component="ResultsCount" data-sentry-source-file="ProfilesListHeader.tsx">
      <ResultsCountInner data-sentry-element="ResultsCountInner" data-sentry-source-file="ProfilesListHeader.tsx" />
    </WaitForTotalCount>;
}
function ResultsCountInner() {
  // const { actor } = useContext(RelayPaginationContext)
  // const totalCount = useSelector(actor, (s) => s.context.totalCount)
  const {
    locationSearch
  } = useContext(AccountsFilterContext);
  return /* Showing {totalCount || 0} results from */<>Showing profiles from {locationSearch.length ? locationSearch : ' around the world'}</>;
}
export function ProfilesListHeader() {
  const [openedFilters, {
    toggle: toggleDisclosure
  }] = useDisclosure(false);
  const {
    freeTextSearch,
    locationSearch
  } = useContext(AccountsFilterContext);
  const {
    professions
  } = useContext(ProfessionsFilterContext);
  const {
    isSignedIn
  } = useAppAuth();
  const pn = usePathname();
  const onContacts = pn.includes('/contacts');
  const {
    mobile
  } = useContext(AppLayoutContext);
  function toggleFilters() {
    toggleDisclosure();
  }
  function calcBadgeTruthy() {
    if (!professions.length && !freeTextSearch.length && !locationSearch.length) {
      return false;
    }
    return true;
  }
  function calcBadgeNumber() {
    let badgeNumber = professions.length + locationSearch.length;

    // Add 1 if freeTextSearch is not empty
    if (freeTextSearch) {
      badgeNumber += 1;
    }
    return badgeNumber;
  }

  // Calc modal size depending on current screen size
  // (do not want to change the mobile state in context but might move this to context
  // under new 'smallScreen' constant?
  const isSmallDevice = useMediaQuery('(max-width: 1100px)');
  const modalSize = isSmallDevice ? 'auto' : '60%';
  return <>
      {!mobile ? <Flex justify="space-between" ml={isSignedIn ? '100px' : '100px'}>
          <Flex mah="60px" align="center" maw="100vw" mt="35px" gap="md">
            <Flex>
              <Button color="black" px="lg" onClick={toggleFilters} data-testid="open-filter-button" leftSection={<AdjustmentsHorizontalIcon width={20} height={20} />} className={filterButtonGroupLeft} fz="h5" tt="uppercase" style={{
            color: '#fff',
            paddingRight: calcBadgeTruthy() ? '30px' : undefined
          }}>
                Filter Profiles
              </Button>
              {calcBadgeTruthy() ? <Badge circle style={{
            position: 'relative',
            right: '30px',
            bottom: '10px'
          }} classNames={{
            label: mobileSortControl
          }}>
                  {calcBadgeNumber()}
                </Badge> : null}
            </Flex>

            <Text size="md" ta="center">
              <ResultsCount />
            </Text>
          </Flex>
        </Flex> : <>
          {onContacts ? <Title order={1} tt="uppercase" px="md" py="xl" mb="0px" mt="sm" data-testid="contacts-title">
              Contacts
            </Title> : <Title order={1} tt="uppercase" px="md" py="xl" mb="0px" mt="sm">
              Community
            </Title>}
          {/* <Text c="dimmed" size="sm" px="md" pt="xs" pb="md">
            <ResultsCount />
           </Text> */}
        </>}

      {/* Search filters */}
      <Modal title={onContacts ? 'FILTER CONTACTS' : 'FILTER COMMUNITY'} opened={openedFilters} onClose={() => toggleFilters()} withCloseButton={false} centered overlayProps={{
      backgroundOpacity: 0.8,
      blur: 0.9,
      color: 'black'
    }} size={modalSize} radius="24px" trapFocus={false} classNames={{
      title: dekstopModalTitle
    }} data-sentry-element="Modal" data-sentry-source-file="ProfilesListHeader.tsx">
        <SearchFilterDesktop toggleFilters={toggleFilters} data-sentry-element="SearchFilterDesktop" data-sentry-source-file="ProfilesListHeader.tsx" />
      </Modal>
    </>;
}

// Old design with other filter buttons design:
// biome-ignore lint/complexity/noUselessLoneBlockStatements: <explanation>
{
  /* {!mobile ? (
  <Flex
  mah="60px"
  align="center"
  maw="100vw"
  px="100px"
  mt="35px"
  gap="md"
  >
  <Flex>
  <Button
  color="black"
  px="lg"
  onClick={toggleFilters}
  data-testid="open-filter-button"
  leftSection={
  <AdjustmentsHorizontalIcon
  width={20}
  height={20}
  ></AdjustmentsHorizontalIcon>
  }
  className={filterButtonGroupLeft}
  style={{
  color: theme.colors.brand[4],
  paddingRight: calcBadgeTruthy() ? '30px' : undefined,
  }}
  >
  {pn.includes('/contacts') ? 'Filter Contacts' : 'Filter Profiles'}
  </Button>
  {calcBadgeTruthy() ? (
  <Badge
  circle
  style={{ position: 'relative', right: '30px', bottom: '10px' }}
  classNames={{
  label: mobileSortControl,
  }}
  >
  {calcBadgeNumber()}
  </Badge>
  ) : null}
  <Button
  color={theme.colors.gray[1]}
  px="lg"
  className={filterButtonGroupMiddle}
  onClick={toggleFilters}
  style={{
  position: calcBadgeTruthy() ? 'relative' : undefined,
  right: calcBadgeTruthy() ? '30px' : undefined,
  }}
  >
  By Profession
  </Button>
  <Button
  color={theme.colors.gray[1]}
  px="lg"
  className={filterButtonGroupRight}
  onClick={toggleFilters}
  style={{
  position: calcBadgeTruthy() ? 'relative' : undefined,
  right: calcBadgeTruthy() ? '30px' : undefined,
  }}
  >
  By Location
  </Button>
  </Flex>
  <Text c="dimmed" size="sm" ta="center">
  {renderResultText()}
  </Text>
  </Flex>
  ) : (
  <Text c="dimmed" size="sm" ta="left" p="sm">
  {renderResultText()}
  </Text>
  )} */
}