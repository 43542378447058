import type { TextareaProps } from '@mantine/core';
import { CommonTextareaField } from '~/app/(me)/_components';
export function ProfileAboutField(props: TextareaProps) {
  const {
    label,
    name,
    ...restProps
  } = props;
  return <CommonTextareaField label="ABOUT" name="profile.about"
  // description="Tell us someting about yourself"
  placeholder="Tell us about yourself." minRows={6} size="md" autosize={true} {...restProps} data-sentry-element="CommonTextareaField" data-sentry-component="ProfileAboutField" data-sentry-source-file="AboutField.tsx" />;
}