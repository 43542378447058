import type { SelectProps } from '@mantine/core';
import { CommonSelectField } from '~/app/(me)/_components';
import { UserPronoun } from '~/generated/graphql';
export function ProfilePronounsField(props: SelectProps) {
  const {
    label,
    name,
    ...restProps
  } = props;
  return <CommonSelectField name="profile.pronoun" label="PRONOUNS" size="md" placeholder="Select your pronouns" data={[{
    value: UserPronoun.She,
    label: 'She/her'
  }, {
    value: UserPronoun.He,
    label: 'He/him'
  }, {
    value: UserPronoun.Them,
    label: 'They/them'
  }]} clearable {...restProps} data-sentry-element="CommonSelectField" data-sentry-component="ProfilePronounsField" data-sentry-source-file="PronounsField.tsx" />;
}