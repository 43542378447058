'use client';

import { Button, Divider, Drawer, Flex, Text, Title } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { accountRoute, meRoute } from '~/utils/routes';
import { WaitForStableAuthState, useAppAuth } from '../../auth';
import { AppLayoutContext, IdentityContext } from '../../context';
import { SafeAvatarImage } from '../../images';
import { AccountType } from '~/generated/graphql';
import { Route } from 'next';
import { navbarItem, noUnderline } from './styles.css';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
type NavPropsType = {
  openedNav: boolean;
  closeNav: () => void;
  openNav: () => void;
};
export function AccountNav({
  openedNav,
  closeNav,
  openNav
}: NavPropsType) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <WaitForStableAuthState context="nav" data-sentry-element="WaitForStableAuthState" data-sentry-component="AccountNav" data-sentry-source-file="AccountNav.tsx">
      {mobile ? <MobileAccountNav /> : <DesktopAccountNav openedNav={openedNav} closeNav={closeNav} openNav={openNav} />}
    </WaitForStableAuthState>;
}
export function SigninButton() {
  const {
    handleSignIn
  } = useAppAuth();
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <Flex gap={mobile ? 'sm' : 'xl'} align="center" data-sentry-element="Flex" data-sentry-component="SigninButton" data-sentry-source-file="AccountNav.tsx">
      {/* <Text fw={600} size="lg" style={{ cursor: 'pointer' }} onClick={handleSignIn} data-testid="signin-button">
        Sign In
       </Text> */}
      <Button px="xs" py="4px" style={{
      cursor: 'pointer',
      backgroundColor: '#48FFB9',
      color: 'black',
      border: '1px solid black',
      fontSize: '12px',
      borderRadius: '8px',
      fontWeight: '600'
    }}
    // onClick={handleSignUp}
    onClick={handleSignIn} data-testid="signin-button" data-sentry-element="Button" data-sentry-source-file="AccountNav.tsx">
        SIGN IN/SIGN UP
      </Button>
    </Flex>;
}
function MobileAccountNav() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  if (!currentAccount?.profile) return <SigninButton />;

  // We do not want to show the name on mobile - to small space
  return null;
}
function DesktopAccountNav({
  openedNav,
  closeNav,
  openNav
}: NavPropsType) {
  const {
    currentAccount
  } = useContext(IdentityContext);

  // const [opened, { open, close }] = useDisclosure(false)

  if (!currentAccount?.profile) return <SigninButton />;
  return <>
      <Button h="100%" data-testid="accountNav-btn" style={{
      backgroundColor: 'transparent'
    }} p="xs" pr="0px" mr="0px" onClick={openedNav ? closeNav : openNav} data-sentry-element="Button" data-sentry-source-file="AccountNav.tsx">
        <SafeAvatarImage radius="xxs" account={currentAccount} data-sentry-element="SafeAvatarImage" data-sentry-source-file="AccountNav.tsx" />
        <Text size="lg" fw={600} mx="xs" tt={'uppercase'} data-sentry-element="Text" data-sentry-source-file="AccountNav.tsx">
          {currentAccount.profile.name}
        </Text>
        {openedNav ? <ChevronUpIcon height={20} /> : <ChevronDownIcon height={20} />}
      </Button>

      <Drawer opened={openedNav} onClose={closeNav} position="right" size="lg" withCloseButton={false} styles={{
      content: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      },
      overlay: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="AccountNav.tsx">
        <DesktopNavDrawer close={closeNav} data-sentry-element="DesktopNavDrawer" data-sentry-source-file="AccountNav.tsx" />
      </Drawer>
    </>;
}
export function DesktopNavDrawer({
  close
}: {
  close: () => void;
}) {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    handleSignOut
  } = useAppAuth();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const router = useRouter();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const MenuItem = ({
    label,
    onClick,
    disabled = false
  }: {
    label: string;
    onClick: any;
    disabled?: boolean;
  }) => <Title tt="uppercase" order={1} onClick={!disabled ? onClick : undefined} style={{
    cursor: disabled ? 'not-allowed' : 'pointer'
  }} c={disabled ? 'dimmed' : undefined} className={navbarItem} data-sentry-element="Title" data-sentry-component="MenuItem" data-sentry-source-file="AccountNav.tsx">
      {label}
    </Title>;
  const handleClick = (path: Route) => {
    close();
    router.push(path);
  };
  const splitName = currentAccount?.profile?.name ? currentAccount?.profile?.name.trim().replace(/\s+/g, ' ').split(' ') : [];
  function LoggedOutNavMenu() {
    return <Flex direction="column" m="xl" h="100%" justify="space-between" data-sentry-element="Flex" data-sentry-component="LoggedOutNavMenu" data-sentry-source-file="AccountNav.tsx">
        <Flex direction="column" gap={mobile ? '5px' : undefined} data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
          <MenuItem label="Community" onClick={() => handleClick('/')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
          <MenuItem label="Projects" onClick={() => handleClick('/projects')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        </Flex>

        <Flex mt="50px" gap="5px" direction="column" data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
          <a href="https://www.createwithdome.com/how-dome-works" target="_blank" rel="noopener noreferrer" style={{
          textDecoration: 'none',
          color: 'inherit'
        }}>
            <Title order={4} tt="uppercase" data-sentry-element="Title" data-sentry-source-file="AccountNav.tsx">
              How Dome Works
            </Title>
          </a>

          <a href="https://www.createwithdome.com/become-a-beta-tester" target="_blank" rel="noopener noreferrer" style={{
          textDecoration: 'none',
          color: 'inherit'
        }}>
            <Title order={4} tt="uppercase" data-sentry-element="Title" data-sentry-source-file="AccountNav.tsx">
              Become a beta tester
            </Title>
          </a>

          <a href="https://www.createwithdome.com/contact" target="_blank" rel="noopener noreferrer" style={{
          textDecoration: 'none',
          color: 'inherit'
        }}>
            <Title order={4} tt="uppercase" data-sentry-element="Title" data-sentry-source-file="AccountNav.tsx">
              Contact
            </Title>
          </a>
        </Flex>
      </Flex>;
  }
  if (!currentAccount && mobile) return <LoggedOutNavMenu />;
  if (!currentAccount) return null;
  return <Flex direction="column" m="xl" h="100%" justify="space-between" data-sentry-element="Flex" data-sentry-component="DesktopNavDrawer" data-sentry-source-file="AccountNav.tsx">
      {mobile ? <Flex gap="sm" direction="column" align="flex-start"
    // data-testid="accountnav-profile-button"
    mb="50px" onClick={() => {
      close();
      router.push(accountRoute(currentAccount.id).show);
    }}>
          <SafeAvatarImage radius="sm" account={currentAccount} />
          <Flex direction="column">
            {splitName.map((name, index) => <Title key={index} tt="uppercase" order={1} className={navbarItem} lh="77%">
                {name}
              </Title>)}
            <Title order={6} tt="uppercase" className={noUnderline}>
              {currentAccount.type === AccountType.Personal ? 'Individual Account' : 'Business Account'}
            </Title>
          </Flex>
        </Flex> : <Flex gap="sm" align="center"
    // data-testid="accountnav-profile-button"
    onClick={() => {
      close();
      router.push(accountRoute(currentAccount.id).show);
    }}>
          <SafeAvatarImage radius="sm" account={currentAccount} />
          <Flex direction="column">
            <Title tt="uppercase" order={1} mb="5px" className={navbarItem}>
              {currentAccount?.profile?.name}
            </Title>
            <Title order={6} tt="uppercase" className={noUnderline}>
              {currentAccount.type === AccountType.Personal ? 'Individual Account' : 'Business Account'}
            </Title>
          </Flex>
        </Flex>}
      {mobile ? null : <Divider my="xl" />}

      {/* <Button variant="outline" tt="uppercase" color="black" mb="100px">
        + Add Account
       </Button> */}

      <Flex direction="column" gap={mobile ? '5px' : undefined} data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
        <MenuItem label="Community" onClick={() => handleClick('/')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Projects" onClick={() => handleClick('/projects')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Contacts" onClick={() => handleClick('/contacts')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <a href={meRoute().editProfile} style={{
        color: 'inherit',
        textDecoration: 'none'
      }}>
          <MenuItem label="Settings" onClick={() => close()} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        </a>
        {/* <MenuItem label="Find friends on Dome" disabled onClick={() => close()} /> */}
        <MenuItem label="Review Dome" onClick={() => handleClick('/review')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Sign out" onClick={() => {
        handleSignOut();
        close();
      }} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
      </Flex>

      {mobile ? <Flex mt="50px" gap="5px" direction="column">
          <a href="https://www.createwithdome.com/how-dome-works" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
            <Title order={4} tt="uppercase">
              How Dome Works
            </Title>
          </a>

          <a href="https://www.createwithdome.com/become-a-beta-tester" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
            <Title order={4} tt="uppercase">
              Become a beta tester
            </Title>
          </a>

          <a href="https://www.createwithdome.com/contact" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
            <Title order={4} tt="uppercase">
              Contact
            </Title>
          </a>
        </Flex> : null}
    </Flex>;
}