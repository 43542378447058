'use client';

import { Badge, Flex, Text, Title } from '@mantine/core';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { AvatarSizes, SafeAvatarImage } from '~/app/_components';
import { AppLayoutContext, IdentityContext } from '~/app/_components/context';
import { AccountTier, AccountType, ConnectionState, TrimmedAccountFragment } from '~/generated/graphql';
import { accountBrand, accountProfessions, accountType } from '~/utils';
import { getPronoun } from '../_lib';
import { ConnectButton } from './common';
import { ProfileDesktopContactInfo } from '../accounts/[id]/[[...pageParams]]/_components';
import { ProBadge } from './desktop';
export function AccountAvatarBlock({
  account
}: {
  account: TrimmedAccountFragment;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const pathname = usePathname();
  const feedPage = !pathname.includes('/accounts');
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const splitName = account.profile?.name ? account.profile?.name.split(' ') : [];
  const size: AvatarSizes = mobile ? !pathname.includes('/accounts') ? 'sm' : 'sm' : 'xl';
  return <Flex direction="column" gap="md" w="100%" data-sentry-element="Flex" data-sentry-component="AccountAvatarBlock" data-sentry-source-file="AccountAvatarBlock.tsx">
      <Flex align={mobile ? 'flex-start' : 'center'} gap={mobile ? 'sm' : 'xl'} w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
        <Flex direction="column" align="center" gap="3px" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
          <SafeAvatarImage radius={size} account={account} data-sentry-element="SafeAvatarImage" data-sentry-source-file="AccountAvatarBlock.tsx" />
          {account.tier === AccountTier.Pro ? <ProBadge /> : null}
        </Flex>
        <Flex direction="column" gap={mobile ? undefined : 'sm'} w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
          <Flex gap="md" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
            {account.connectionStatus === ConnectionState.Connected && pathname.includes('/accounts') ? <Badge size="xs" tt="uppercase" data-testid="connected-badge" fz="xs" color="gray.2" mb="5px">
                Connected
              </Badge> : null}
            {!feedPage && account.profile?.pronoun ? <Text c="dimmed" size="md">
                {getPronoun(account.profile?.pronoun)}
              </Text> : null}
          </Flex>

          <Flex gap="xl" justify="space-between" mb="2px" align="flex-start" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
            <Flex w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlock.tsx">
              {feedPage ? <Title tt="uppercase" mb="2px" order={4} data-testid="profile-title">
                  {account.profile?.name}
                </Title> : <Flex gap="sm" justify="space-between" w="100%">
                  <Flex direction="column" data-testid="profile-title">
                    {splitName.map((name, index) => <Title key={index} tt="uppercase" order={2} lh="119%">
                        {name}
                      </Title>)}
                  </Flex>

                  {account.connectionStatus !== ConnectionState.Connected && !isCurrentUserProfile ? <ConnectButton disablePadding justify="flex-end" /> : null}
                </Flex>}
            </Flex>
          </Flex>

          {account.type !== AccountType.Personal ? <Text lh={feedPage ? '15px' : '12px'} c="black" lts={feedPage ? undefined : '-0.4px'} tt="uppercase" fw={600} size="12px">
              {accountType(account)}
            </Text> : null}
          {account.profile?.brand ? feedPage ? <Text c="dimmed" size="md">
                {accountBrand(account)}
              </Text> : <Title order={5} tt="uppercase" mb="4px">
                {accountBrand(account)}
              </Title> : null}
          {account.type === AccountType.Personal && account.professions && account.professions.length >= 1 ? feedPage ? <Text c="dimmed" size="md">
                {accountProfessions(account)}
              </Text> : <Title order={5} tt="uppercase" mb="4px">
                {accountProfessions(account)}
              </Title> : null}
          {account.profile?.location ? feedPage ? <Text c="dimmed" size="md">
                {account.profile.location}
              </Text> : <Title order={5} tt="uppercase" mb="4px">
                {account.profile.location}
              </Title> : null}
        </Flex>

        {account.connectionStatus === ConnectionState.Connected && !feedPage ? <ProfileDesktopContactInfo /> : null}
      </Flex>
    </Flex>;
}