'use client';

import { graphql } from '~/generated/gql';
export const GetPaginatedFriends = graphql(/* GraphQL */`
  query GetPaginatedFriends(
    $first: Int!
    $after: String
    $query: AccountsSearchQuery
  ) {
    currentAccount {
      __typename
      ... on Account {
        __typename
        id
        friends(first: $first, after: $after, query: $query) {
          __typename
          edges {
            __typename
            cursor
            __typename
            node {
              ...FullAccountAsViewer
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
`);