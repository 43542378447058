'use client';

// import { createActorContext } from '@xstate/react'
import { graphql } from '~/generated/gql';
export const FriendRequesterFragment = graphql(/* GraphQL */`
`);
export const GetPaginatedFriendRequests = graphql(/* GraphQL */`
  query GetPaginatedFriendRequests($first: Int!, $after: String) {
    currentAccount {
      __typename
      ... on Account {
        id
        createdAt
        requestedFriendships(first: $first, after: $after) {
          __typename
          edges {
            cursor
            __typename
            node {
              __typename
              id
              requester {
                id
                createdAt
              }
              addressee {
                id
                createdAt
                profile {
                  name
                  location
                  brand
                }
                professions {
                  id
                  name
                }
                avatarUrl
                folioItems {
                  file {
                    url
                  }
                  id
                  order
                }
                connectionStatus
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
`);