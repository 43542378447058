import dynamic from 'next/dynamic';
import { Dispatch, SetStateAction } from 'react';
import { type FileCreateInput, type FileType } from '~/generated/graphql';
import React from 'react';
const DynamicImageUploader = dynamic(() => import('./DynamicImageUploader.dynamic'), {
  loading: () => <p>Loading...</p>
});
export type ImageUploaderProps = {
  addCaption?: boolean;
  setCaption?: Dispatch<SetStateAction<string | undefined>>;
  onChange: (file: FileCreateInput) => void;
  open: boolean;
  onClose: () => void;
  fileType: FileType;
  maxNumberOfFiles?: number;
  shouldCloseAfterFinish: boolean;
};
export function ImageUploader(props: ImageUploaderProps) {
  return <DynamicImageUploader {...props} data-sentry-element="DynamicImageUploader" data-sentry-component="ImageUploader" data-sentry-source-file="ImageUploader.tsx" />;
}