/* eslint-disable react/prop-types */
import Image from 'next/image';
import { ComponentProps } from 'react';
import { SafeImage } from '~/app/_components/images';
import { TrimmedAccountFragment } from '~/generated/graphql';
type NextImageProps = ComponentProps<typeof Image>;
type SafeAvatarParams = Omit<NextImageProps, 'src' | 'alt'> & ({
  src: NextImageProps['src'];
  account?: never;
} | {
  src?: never;
  account: TrimmedAccountFragment;
}) & {
  radius?: AvatarSizes;
};
const radiuses = {
  xxs: 30,
  xs: 34,
  sm: 56,
  md: 84,
  xmd: 105,
  lg: 120,
  xl: 135,
  xxl: 150
};
export type AvatarSizes = keyof typeof radiuses;
export function SafeAvatarImage({
  src,
  account,
  radius
}: SafeAvatarParams) {
  const properSrc = src ?? account.avatarUrl;
  const size = radiuses[radius ?? 'md'];
  return <div style={{
    position: 'relative',
    height: size,
    minWidth: size
  }} data-sentry-component="SafeAvatarImage" data-sentry-source-file="SafeAvatarImage.tsx">
      <SafeImage alt="avatar" fill sizes={`${size}px`} style={{
      objectFit: 'cover',
      borderRadius: '50%',
      border: '0.5px solid #f1f1f1'
    }} src={properSrc}
    // sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, (min-width: 1407px) 33vw"
    data-sentry-element="SafeImage" data-sentry-source-file="SafeAvatarImage.tsx" />
    </div>;
}