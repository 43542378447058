'use client';

import { PlusIcon } from '@heroicons/react/24/outline';
import { Flex, Grid, Modal, Stack, Text, TextInput, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useClient, useMutation } from '@urql/next';
import { ReactNode, Suspense, useContext, useEffect, useState } from 'react';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { AppLayoutContext, IdentityContext, ImageUploader, StyledButton } from '~/app/_components';
import { type AccountUpdateInput, type FileCreateInput, FileType } from '~/generated/graphql';
import { GalleryGrid } from './gallery';
import { usePathname } from 'next/navigation';
import { appTrace } from '~/utils';
export const FOLIO_ITEMS_LIMIT = 9;
export function AddFolio() {
  const [open, setOpen] = useState(false);
  const [caption, setCaption] = useState<string | undefined>('');
  const [folioFile, setFolioFile] = useState(null as null | FileCreateInput);
  const [videoURL, setVideoURL] = useState('');
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const pn = usePathname();
  const isOnboarding = pn.includes('/onboarding');
  const [opened, {
    open: videoOpen,
    close
  }] = useDisclosure(false);
  const folioImagesLeft = FOLIO_ITEMS_LIMIT - (currentAccount?.folioItems?.length || 0);
  const maxFolioImagesReached = folioImagesLeft === 0;
  const client = useClient();
  const [, update] = useMutation(updateCurrentAccount);
  useEffect(() => {
    if (!folioFile) return;
    const account: AccountUpdateInput = {
      folioItems: {
        create: [{
          caption: caption,
          file: {
            create: folioFile
          }
        }]
      }
    };
    setFolioFile(null);
    update({
      account
    });
  }, [folioFile, client, update, caption]);

  // const handleAddVideo = () => {
  //   const video = extractVideoInfo(videoURL)
  //   if (video) {
  //     setVideoURL('')

  //     const account: AccountUpdateInput = {
  //       videoItems: {
  //         create: [{ url: video.id, kind: video.platform }],
  //       },
  //     }
  //     update({ account })
  //     close()
  //   }
  // }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  // const extractVideoInfo = (url: string): any | null => {
  //   const youtubeMatch = url.match(
  //     /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
  //   )
  //   const vimeoMatch = url.match(
  //     /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?))/
  //   )

  //   if (youtubeMatch?.[1]) {
  //     return { id: youtubeMatch[1], platform: 'youtube' }
  //   }
  //   if (vimeoMatch?.[1]) {
  //     return { id: vimeoMatch[1], platform: 'vimeo' }
  //   }
  //   return null
  // }

  const gridColHeight = mobile ? '80px' : '130px';
  const plusIconHeight = mobile ? 25 : 35;
  // const videoCameraIconHeight = mobile ? 20 : 40
  // const textFontSize = mobile ? 'xs' : 'sm'
  const textFontWeight = mobile ? 500 : 600;
  function GridColumn({
    children,
    grayedOut,
    type
  }: {
    children: ReactNode;
    grayedOut?: boolean;
    type?: 'image' | 'video' | 'instagram';
  }) {
    const handleClick = () => {
      appTrace('handleClick', {
        type
      });
      if (type === 'image') {
        setOpen(true);
      } else if (type === 'video') {
        videoOpen();
      }
    };
    return <Grid.Col span={12} h={gridColHeight} onClick={type === 'image' || type === 'video' ? handleClick : undefined} data-testid={`add-folio-button-${type}`} data-sentry-element="unknown" data-sentry-component="GridColumn" data-sentry-source-file="AddFolio.tsx">
        <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-evenly',
        gap: '5px',
        border: grayedOut ? '1px solid #d6d5d2' : '1px dotted black',
        width: '100%',
        height: '100%',
        padding: mobile ? '5px' : '10px',
        cursor: grayedOut ? 'not-allowed' : 'pointer'
      }}>
          {children}
        </div>
      </Grid.Col>;
  }
  return <>
      <Stack data-sentry-element="Stack" data-sentry-source-file="AddFolio.tsx">
        <Flex direction="column" gap={0} data-sentry-element="Flex" data-sentry-source-file="AddFolio.tsx">
          <Title order={3} fw={600} data-sentry-element="Title" data-sentry-source-file="AddFolio.tsx">
            FOLIO
          </Title>

          <Text size="md" c="dimmed" fw={500} pt="xs" pb="sm" data-sentry-element="Text" data-sentry-source-file="AddFolio.tsx">
            You'll need at least 1 folio image to make your profile visible to other users. You can update these through
            your profile later.
          </Text>
          {mobile ? null : <Suspense>
              <GalleryGrid />
            </Suspense>}
        </Flex>
        {/* {currentAccount?.videoItems?.length ? (
          <Flex direction="column" gap={0}>
            <Title order={6} pb="sm">
              Folio Videos
            </Title>
            <Suspense>
              <VideoGallery />
            </Suspense>
          </Flex>
         ) : null} */}
        <ImageUploader addCaption={!isOnboarding} setCaption={!isOnboarding ? setCaption : undefined} maxNumberOfFiles={folioImagesLeft} fileType={FileType.Folio} open={open} onChange={setFolioFile} onClose={() => setOpen(false)} shouldCloseAfterFinish={true} data-sentry-element="ImageUploader" data-sentry-source-file="AddFolio.tsx" />
        {/* Youtube/vimeo upload */}
        {/* <Modal opened={opened} onClose={close} title="Video Upload" centered>
          <Text size="xs" c="dimmed">
            Paste the video's full URL below
          </Text>
          <Text size="xs" c="dimmed">
            We currently support Youtube and Vimeo links.
          </Text>
          <TextInput
            value={videoURL}
            onChange={(e) => setVideoURL(e.target.value)}
            my="md"
            placeholder="E.g. https://www.youtube.com/watch?v=N9TT5kO4p7Q"
          />
          <Flex w="100%" justify="flex-end">
            <StyledButton type="primary" text="Add video" onClick={() => handleAddVideo()} />
          </Flex>
         </Modal> */}
      </Stack>
      <Grid gutter="sm" data-sentry-element="Grid" data-sentry-source-file="AddFolio.tsx">
        {maxFolioImagesReached ? <GridColumn>
            <Tooltip label={`Maximum number of portfolio images (${FOLIO_ITEMS_LIMIT}) reached`}>
              <PlusIcon height={plusIconHeight} style={{
            cursor: maxFolioImagesReached ? 'not-allowed' : 'pointer'
          }} />
            </Tooltip>
            {<Text ta="center" size="md" fw={textFontWeight} style={{
          cursor: maxFolioImagesReached ? 'not-allowed' : 'pointer'
        }}>
                Add folio images from your device
              </Text>}
          </GridColumn> : <GridColumn type="image">
            <PlusIcon height={plusIconHeight} data-testid="add-folio-image" />
            {<Text ta="center" size="md" fw={textFontWeight}>
                Add folio images from your device
              </Text>}
          </GridColumn>}
      </Grid>

      {mobile ? <Suspense>
          <GalleryGrid />
        </Suspense> : null}
    </>;
}

// <GridColumn type="video">
//   <VideoCameraIcon height={videoCameraIconHeight} />
//   {
//     <Text ta="center" size={textFontSize} fw={textFontWeight}>
//       Add videos from Youtube/Vimeo
//     </Text>
//   }
// </GridColumn>

// <GridColumn grayedOut>
//   <Tooltip label="Coming soon!">
//     <CameraIcon height={mobile ? 20 : 40} color="#d6d5d2" />
//   </Tooltip>
//   <Text ta="center" size={textFontSize} fw={textFontWeight} c="#d6d5d2">
//     Add content from Instagram
//   </Text>
//   <Text tt="uppercase" ta="center" size="10px" fw={textFontWeight} c="#d6d5d2">
//     (Coming soon!)
//   </Text>
// </GridColumn>