import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2Fprojects%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VVW2vbMBR%2B76%2FwGIV24GB7ddomb1kpK6wwlsIeg2wdO2pkSUhKLxv775VlK5btpOkeQshxvsv5dHQ8UfqVglohjBdbrTlbrdLrtEqrKPh7EgT5ViouZ4HghGmQ85N%2FJ5MWgUFtNBc%2FJX%2BEXH9DEjtobKFrIOVaz4I0jcTL3BQylG9KybcMhzmnNStha5BE%2B6yioVtqpGGBcAmONGn8NMCMGq49ZpZADRjwHlNfLb4iLHTG4qPGgk%2BkElxqxHoeK54RCp7Gsqm3SheNEnoJnwnW61lwnZ5aGS4xyDDjJubKyIuXQHFKcPCE5FkYVkaFMGgshKVEr2F8PveCRFvN55ZZloTNgqitdMZybg6J6aWxRTi7tzadq7R1VWN3HnJE87N4kqQSquDLwIgyT%2BH8fG%2Fnhfn6LZEQIJ3A1Aq0LcdRdOoDKc9R7emOia0eAC99ZxQK02piT8ZrzDhhJrzvgHAHvPIVL9LmMF1aze%2BjFDOKlA55EepXAWMjA5J2Om0XtyZsR3NtkYWphIr8AXM2k3RqU%2FXRpST4jrFOHQ0nZRhbxTGiD0R3x5gNlZLEzJE%2Fp%2B3T5zaHyyg6MMYYNsrcmfuRRt5pHGI5ZkGYfUJY2aYYpwf%2FYAzseT5I4JYAxT2LeBhDPP0oRew44GiUHQfx5nZh77FjKSxLc7f9S%2F05GkXWLgCJMNmqekbaCfloI8putxvJxQ1%2F3i3q8j%2BikFDcVagEZQ%2FdMax7%2B7qewQNwZPfKA8rUD5TBDk9GDq6au9gboWkU7eeqP47qsUlztJC1REwJJM12e3fSondaV4IzRZ7gVxuC09z4aySJ2pdCfStdJF3Re33sijyrd0JYEFPM%2BVP%2FLTmWXZDSKdPeAks8kVHNc9P746i4z80bbPOBD%2BUHAAA%3D%22%7D"
export var actionTabsLabel = 'styles_actionTabsLabel__595m5mi';
export var actionTabsTabs = 'styles_actionTabsTabs__595m5mj';
export var addButton = 'styles_addButton__595m5m0';
export var calenderHeader = 'styles_calenderHeader__595m5m8';
export var contentSectionMobile = 'styles_contentSectionMobile__595m5m5';
export var dekstopModalTitle = 'styles_dekstopModalTitle__595m5mc';
export var desktopProjectCard = 'styles_desktopProjectCard__595m5m1';
export var desktopSelectedProjectCard = 'styles_desktopSelectedProjectCard__595m5m3';
export var gridInner = 'styles_gridInner__595m5ma';
export var inputWrapperBorder = 'styles_inputWrapperBorder__595m5mf';
export var locationInputWrapper = 'styles_locationInputWrapper__595m5m7';
export var mobileProfileWrapper = 'styles_mobileProfileWrapper__595m5m6';
export var mobileProjectCardStyles = 'styles_mobileProjectCardStyles__595m5m4';
export var modalFieldTitle = 'styles_modalFieldTitle__595m5md';
export var modalFieldTitle1 = 'styles_modalFieldTitle1__595m5me';
export var modalTitle = 'styles_modalTitle__595m5mb';
export var projectInputFont = 'styles_projectInputFont__595m5m9';
export var projectStateBadge = 'styles_projectStateBadge__595m5m2';
export var refImagesModal = 'styles_refImagesModal__595m5mh';
export var responsiveRefImage = 'styles_responsiveRefImage__595m5mk';
export var responsiveRefImageBig = 'styles_responsiveRefImageBig__595m5ml';
export var selectDropDown = 'styles_selectDropDown__595m5mg';