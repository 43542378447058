import { Flex, Input, type MultiSelectProps, type SelectProps, type TextInputProps, type TextareaProps } from '@mantine/core';
import { useContext } from 'react';
import React from 'react';
import { type FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { MultiSelect, Select, TextInput, Textarea } from 'react-hook-form-mantine';
import { textInputRoot } from '~/app/(me)/_components/common/styles.css';
import { AppLayoutContext, ValidationBadge } from '~/app/_components';
export type CommonFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label?: string | React.ReactNode;
};
export function CommonFieldLabel<T extends FieldValues>(props: React.PropsWithChildren<T>) {
  if (!props.label && !props.children) return null;
  return <Flex align="center" data-sentry-element="Flex" data-sentry-component="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx">
      {props.label}
      {props.children}
      <ValidationBadge field={props.name} data-sentry-element="ValidationBadge" data-sentry-source-file="CommonFields.tsx" />
    </Flex>;
}
export function CommonTextField<T extends FieldValues>(props: TextInputProps & CommonFieldProps<T>) {
  const profileForm = useFormContext();
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  // WARNING weird bug - if remove field.onChange it does not update
  const {
    field
  } = useController({
    name
  });
  return <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonTextField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <TextInput w="100%" placeholder={title} name={name} key={name} control={profileForm.control} size="md" autoCapitalize="true" onChange={field.onChange} classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="TextInput" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}
export function CommonTextareaField<T extends FieldValues>(props: TextareaProps & CommonFieldProps<T>) {
  const profileForm = useFormContext();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  return <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonTextareaField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <Textarea w="100%" key={name} placeholder={title} name={name} control={profileForm.control} size={mobile ? 'xl' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="Textarea" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}
export function CommonMultiselectField<T extends FieldValues>(props: MultiSelectProps & CommonFieldProps<T>) {
  const profileForm = useFormContext();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  return <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonMultiselectField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <MultiSelect w="100%" placeholder={title} name={name} control={profileForm.control} size={mobile ? 'md' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="MultiSelect" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}
export function CommonSelectField<T extends FieldValues>(props: SelectProps & CommonFieldProps<T>) {
  const form = useFormContext();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  return <Input.Label fz="h3" fw={600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonSelectField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />

      <Select w="100%" name={name} control={form.control} placeholder={props.placeholder || title} size={mobile ? 'xl' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="Select" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}