'use client';

import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { get, useFormContext, useWatch, useFormState } from 'react-hook-form';
import { ZodObject } from 'zod';
import { FormValidationContext } from '~/app/_components/context';
function hasNestedField(schema: ZodObject<any>, path: string): boolean {
  const keys = path.split('.');
  let currentSchema: ZodObject<any> | undefined = schema;
  for (const key of keys) {
    if (currentSchema instanceof ZodObject) {
      const shape = currentSchema.shape as Record<string, ZodObject<any>>;
      currentSchema = shape[key];
    } else {
      return false;
    }
  }
  return currentSchema !== undefined;
}
export function ValidationBadge(props: {
  field: string;
}) {
  const colors = {
    inactive: 'lightgray',
    valid: '#48FFB9',
    invalid: 'red'
  };
  const form = useFormContext();
  const {
    schema
  } = useContext(FormValidationContext);

  // Subscribe to the field's value changes
  const value = useWatch({
    control: form.control,
    name: props.field
  });

  // Subscribe to the field's error and touched states
  const {
    errors,
    touchedFields,
    dirtyFields
  } = useFormState({
    control: form.control
  });
  const error = get(errors, props.field);
  const isTouched = get(touchedFields, props.field);
  const isDirty = get(dirtyFields, props.field);
  const isInteractedWith = isTouched || isDirty;
  const isEmpty = !value || Array.isArray(value) && value.length === 0;
  let color = isEmpty ? colors.inactive : colors.valid;
  if (error) {
    color = isInteractedWith ? colors.invalid : colors.inactive;
  }
  if (!hasNestedField(schema, props.field)) {
    return null;
  }
  return <CheckBadgeIcon height={20} style={{
    marginLeft: '5px'
  }} color={color} data-sentry-element="CheckBadgeIcon" data-sentry-component="ValidationBadge" data-sentry-source-file="ValidationBadge.tsx" />;
}