import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(accounts)%2F_components%2Fdesktop%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51VXWvbMBR9z6%2FQ6EsycHCyfPdlZJAt0MIohT0Gxbq2tcqSkeR8bPS%2F78qOaidNkzBCCb3Suefce49uusbuBZgVA%2FNiVf5Tq5gL%2BEY1W62iyWA%2FpiH52yIko7tgy5lNZ2QUhvnu3sW49LH%2BdFjF1jR6SbQqJAsiJZSekUIasOQTz3KlLZW2vKU0AzwLuwgjRgnOyN2i5z73rddW90NRP4Ai0EvrldIYN7mg%2BxmJBZQaqOCJDLiFzMxIBNKCduHfhbE83qMujEjbPEpojv9SEbXD7nioISOfyYbqdhBkqJhLCAyeQqfjy06BJymmmPhWYHuOY5eqeOZWgC%2Bif74I9x0wriGyXElUp0SRSXfieXp%2BDjlljMskWCtrVXYopNftXy6kFpjXyvBPFQbEL03zvG70l1Kj58FicHTnuU9qvyH1LFUb0CVBVGjjLJMrXk2mTpSBLJY4UQ8b3AZDcowsceQeOKwglTe3KbqkeZ%2FLDUYeFaNiKfPCetSoRMXonMDwP4DNH6Bxm6aucxigOkqfYWePUoyPeng0q6umOy1oXiBafseG5g8Qv1FMSorqcbkpBQIPA00ZL%2FAlTA4vtDquBFy64RJoZ7XLGc5cuSD3kTNWe3%2FaFOxThEcs%2BmD2ek%2FEcXyR4skhPAO9zvC%2Bzt7oeqHVnXdOcxN%2FoGsQXsD6sD51gmvDT%2F2wLBt%2BCrvT0gNv4e3hlY%2FD8ORBzSlL4Igjanr6bjBZLOZTB%2FqaAeOUmEgDSEIlI%2B3Gzh6PcFidEnqb%2FevV0w83qVP6%2BgFJ%2FWPxHyTn19mVpYxK%2FgFpbjGYywYAAA%3D%3D%22%7D"
export var desktopProfileCard = 'styles_desktopProfileCard__c84y7a0';
export var desktopProfileCardHeader = 'styles_desktopProfileCardHeader__c84y7a1';
export var desktopProfileCardTitle = 'styles_desktopProfileCardTitle__c84y7a2';
export var filterButtonGroupLeft = 'styles_filterButtonGroupLeft__c84y7a8';
export var filterButtonGroupMiddle = 'styles_filterButtonGroupMiddle__c84y7a9';
export var filterButtonGroupRight = 'styles_filterButtonGroupRight__c84y7aa';
export var filterIcon = 'styles_filterIcon__c84y7a5';
export var filterInputLabel = 'styles_filterInputLabel__c84y7ab';
export var inviteModalInput = 'styles_inviteModalInput__c84y7a6';
export var menuItem = 'styles_menuItem__c84y7a4';
export var proBadgeLabel = 'styles_proBadgeLabel__c84y7ac';
export var profileCardCarouselWrapper = 'styles_profileCardCarouselWrapper__c84y7a3';
export var searchTextInput = 'styles_searchTextInput__c84y7a7';