'use client';

import { Flex, Text, Title } from '@mantine/core';
import { useClient } from '@urql/next';
import { useSelector } from '@xstate/react';
import { useContext, useEffect, useState } from 'react';
import { CurrentAccountValidationField } from '~/app/(me)/_components/common';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { AppLayoutContext, AuthActorContext, IdentityContext, ImageUploader, SafeAvatarImage, ValidationBadge } from '~/app/_components';
import { AccountType, type AccountUpdateInput, type FileCreateInput, FileType } from '~/generated/graphql';
import { accountProfessions, accountType } from '~/utils';
import { icon } from '../styles.css';
import { PlusIcon } from '@heroicons/react/24/outline';
export function AddProfileImage() {
  const [open, setOpen] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null as null | FileCreateInput);
  const client = useClient();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  useEffect(() => {
    if (!avatarFile) return;
    const input: AccountUpdateInput = {
      avatar: {
        create: {
          file: {
            create: avatarFile
          }
        }
      }
    };
    setAvatarFile(null);
    client.mutation(updateCurrentAccount, {
      account: input
    }).toPromise().then(result => {
      if (result.data?.updateCurrentAccount?.__typename === 'Account') {
        // TODO: this should not be necessary as the graphcache updates automatically
        // and identitycontext sends this update already
        authActor.send({
          type: 'CURRENT_ACCOUNT',
          data: {
            account: result.data?.updateCurrentAccount
          }
        });
      }
    });
  }, [authActor, avatarFile, client]);
  const avatarUrl = useSelector(authActor, s => s.context.account?.avatarUrl);
  return <>
      <Flex align="center" data-sentry-element="Flex" data-sentry-source-file="AddProfileImage.tsx">
        <Title order={3} fw={600} data-sentry-element="Title" data-sentry-source-file="AddProfileImage.tsx">
          PROFILE IMAGE
        </Title>
        <ValidationBadge field="currentAccount.avatarUrl" data-sentry-element="ValidationBadge" data-sentry-source-file="AddProfileImage.tsx" />
        <CurrentAccountValidationField name="currentAccount.avatarUrl" data-sentry-element="CurrentAccountValidationField" data-sentry-source-file="AddProfileImage.tsx" />
      </Flex>
      <Flex w="100%" justify="flex-start" align="center" onClick={() => setOpen(true)} className={icon} gap="sm" data-sentry-element="Flex" data-sentry-source-file="AddProfileImage.tsx">
        {!avatarUrl ? <Flex direction="column" data-testid="add-profile-button" style={{
        minWidth: 130,
        width: 130,
        height: 130,
        backgroundColor: '#CBBDFF',
        // Lilac color
        borderRadius: '50%',
        // Make it a circle
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            {mobile ? null : <PlusIcon height={24} />}
            <Text fw={600} mt={mobile ? undefined : 'xs'}>
              {mobile ? 'Add' : 'Click to add'}
            </Text>
            <Text fw={600}>Profile Image</Text>
          </Flex> : <div style={{
        position: 'relative',
        display: 'inline-block'
      }}>
            {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
            <SafeAvatarImage radius="lg" account={currentAccount!} />
            <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '40px',
          width: '60px',
          // backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontWeight: 'bolder',
          fontSize: '16px'
        }}>
              Update
            </div>
          </div>}
        <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="AddProfileImage.tsx">
          <Title order={4} tt="uppercase" mb="4px" data-sentry-element="Title" data-sentry-source-file="AddProfileImage.tsx">
            {currentAccount?.profile?.name}
          </Title>
          <Text size="md" fw={600} data-sentry-element="Text" data-sentry-source-file="AddProfileImage.tsx">
            {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}

            {currentAccount?.type === AccountType.Personal ? accountProfessions(currentAccount!) : accountType(currentAccount!)}
          </Text>
          <Text size="md" fw={600} data-sentry-element="Text" data-sentry-source-file="AddProfileImage.tsx">
            {currentAccount?.profile?.location}
          </Text>
        </Flex>
      </Flex>
      <ImageUploader fileType={FileType.Avatar} open={open} onChange={setAvatarFile} onClose={() => setOpen(false)} shouldCloseAfterFinish={true} data-sentry-element="ImageUploader" data-sentry-source-file="AddProfileImage.tsx" />
    </>;
}