'use client';

import { useDisclosure } from '@mantine/hooks';
import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CommonMultiselectField } from '~/app/(me)/_components/common/form';
import { RemoteConfigContext } from '~/app/_components';
export function ProfessionsSelect() {
  const {
    professions
  } = useContext(RemoteConfigContext);
  // const { mobile } = useContext(AppLayoutContext)
  const profileForm = useFormContext();

  // const pn = usePathname()

  const _professionsField = useController({
    name: 'professions',
    control: profileForm.control
  });
  const [_openedProfessionSelection, {
    open: openProfessionSelection,
    close: _closeProfessionSelection
  }] = useDisclosure(false);
  const profSelectProps = {
    // label: 'Profession',
    // placeholder: 'Pick your profession(s)',
    data: professions.map(p => ({
      value: p.id,
      label: p.name
    })),
    control: profileForm.control
  };
  const _onProfClick = () => openProfessionSelection?.(); //   why 2??
  const val = profileForm.watch('professions');
  return <>
      <CommonMultiselectField name="professions" label="PROFESSION" {...profSelectProps} placeholder={val.length ? '' : 'Select'} data-testid="professions-select" data-sentry-element="CommonMultiselectField" data-sentry-source-file="ProfessionsSelect.tsx" />
    </>;
}