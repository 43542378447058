import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(me)%2F_components%2Fcommon%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA52UTU%2BDQBCG7%2F0VG73YAw3F0iiNB7%2BamHgyvRnTbNmBblx2yLBgq%2FG%2Fu6WFQCNKeuCwu%2FO%2B88zOLKPMbBVkyxi0ALpHbQjVcukV2204dtnXgLEQFVLAVoqH77PB92DUkrwgmip%2BXMavbFxMmGvhHKQfa2lgtjtCspKAjdMNy1BJUbkylnIhpI4D5qab3TrhFEt9WB4nfdJpXmf1yqwd8QY2poxucl7uObtgOtQP3EDlMPnboTpySMZrEzCNGpq7XMg8C9jE6tzDN9mXHeaU7a4sRakNUCfMQiY1jN8TRkHUzXJgqJiaiVPCSCqYS1CiBHjmK6iHZFpmj%2BzkOJn8BAswbcvzVCEXtwU3nO6MrnRXpc4Q15k0Em3rjgeH%2Ba6bZH2sgjUWQB3TV3C6cJyEayM17DedmPjWGQ9nJxLM7f1iA%2BC6dy31c%2Bqkmgx75GsU%2FJ%2Bff0KVjVfW6jXv8UP4RfUqbMMcHhpZwM2ZoRzO3ppOqdQtowgpeSRCWtiRr1xWTcV5xH3P92btyXNH%2FtTzCRJr9gMRLnxh2QQAAA%3D%3D%22%7D"
export var contactInfoDescr = 'styles_contactInfoDescr__2vyyc17';
export var formErrorText = 'styles_formErrorText__2vyyc1b';
export var genderControl = 'styles_genderControl__2vyyc10';
export var genderInput = 'styles_genderInput__2vyyc12';
export var genderInputLabel = 'styles_genderInputLabel__2vyyc1a';
export var genderRoot = 'styles_genderRoot__2vyyc11';
export var profileFieldInputLabel = 'styles_profileFieldInputLabel__2vyyc16';
export var textInputRoot = 'styles_textInputRoot__2vyyc13';
export var textInputRootDate = 'styles_textInputRootDate__2vyyc14';
export var textInputRootTime = 'styles_textInputRootTime__2vyyc15';
export var uploadAvatarBtn = 'styles_uploadAvatarBtn__2vyyc18';
export var uploadFolioBtn = 'styles_uploadFolioBtn__2vyyc19';